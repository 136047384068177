<template>
  <nav class="menu">
    <router-link to="/">電卓の道</router-link> 
    <router-link to="/about">作者プロフィール</router-link> 
    <router-link to="/other">その他</router-link>
  </nav>
  <router-view/>
</template>
<style>
  html{
    height: 100%;
    background-color: #192e40;
  }
  body{
    margin: 0;
  }
  .menu{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100vw;
    height: 80px;
    left: 0;
    padding: 0;
    margin: 0;
    background-color: #192E40;
    background-color: linear-gradient();
  }
  .menu a{
    margin-top: 2rem;
    margin-left: .5rem;
    margin-right: .5rem;
    text-decoration:none;
    color: #fff;
  }
  a.active{
    border-bottom: 1px solid #1482AE;
  }
  .menu a:nth-of-type(1){
    font-family: "vdl-linegpop-shadow", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 25pt;
    margin-top: .4rem;
  }
  .menu a:nth-of-type(2){
    font-family: "ab-kokikaku", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-left: .5rem;
  }
  .menu a:nth-of-type(3){
    font-family: "ab-kokikaku", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .home{
    height: auto;
    background: linear-gradient(#052A59,#192E40);
  }
</style>
<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
}



nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #A0DBF2;
      border-bottom: 2px solid #A0DBF2;
    }
  }
}
</style>
 
