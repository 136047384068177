<template>
  <div class="about">
    <h1>作者プロフィール</h1>
    <p>
      作者：情報システム科
    </p>
    <p>
      Web・CGデザインコース2年
    </p>
    <p>
      菅 莉々子
    </p>
  </div>
</template>
<style>
  p{
    margin: .5rem;
  }
</style>
