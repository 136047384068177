<template>
  <div class="home">
    <nav id="menu">
      <button id="icon" @click="toggle">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul>
        <li><a href="#" @click.prevent.stop=""></a></li>
      </ul>
    </nav>
    <header>
      <label for="keta">ケタ数</label>
      <select id="keta" v-model="keta">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select><!-- /# -->
      <label for="item">項数</label>
      <select id="item" v-model="item">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
      </select><!-- /# -->
      <button id="generate" ref="gene" @click="onclick">生成</button>
    </header>
    <main>
      <div class="p">
        <p v-for="i in que" :key="i">{{ i }}</p>
      </div>
        <div class="plus" v-show="flag"></div>
      <hr />
      <div class="flex">
        <input ref="input" type="text" class="right" v-model="answer" />
        <button @click="saiten1();saiten2()" class="saiten">採点</button>
      </div>

      <!-- 何かしら入力しないと判定しない -->
      <div v-show="answer.length > 0">
        <div class="answer" v-if="answer.length > 0 && answer == correct">正解！</div>
        <div class="answer" v-if="answer.length > 0 && answer != correct">うーん</div>
      </div>

      <!-- <p>答え：{{ correct }}</p> -->
    </main>
    <aside>
      <h1>{{savelog.length}}問中{{marucount()}}問正解</h1>
      <h2>正答率{{ maruper() }}％</h2>
      <p class="hantei" v-for="i in savelog" :key="i">{{ i.hantei }}／{{ i.time }}</p>
    </aside>
  </div>
</template>
<style>
  .right{
    text-align: right;
    outline: 0;
  }
  #icon{
    display: block;
    box-sizing: content-box;
    position:fixed;
    border: none;
    opacity: 1;
    top: 6rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    background-color: transparent;
    z-index: 105;
  }
  #icon span{
    position:relative;
    display: block;
    opacity:1;
    width: 2rem;
    height: 0.3em;
    transition: all 300ms ease 0s;
    background-color: #fff;
  }
  #icon span:nth-of-type(2){
    margin:.5rem 0;
  }
  #icon.on span:nth-of-type(1){
  transform:rotate(45deg);
  top: 0.75rem;
  background-color: #192e40;
  }
  #icon.on span:nth-of-type(2){
    left:100%;
    opacity:0;
    background-color: #192e40;
  }
  #icon.on span:nth-of-type(3){
    transform:rotate(-45deg);
    top: -0.75rem;
    background-color: #192e40;
  }
  main{
  }
  .plus{
    position: relative;
    margin-left: 3rem;
    width: 20px;
    height: 20px;
    background-color: transparent;
    bottom: .3rem;
  }
  .plus::before{
    content:"";
    position: absolute;
    top:calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
  .plus::after{
    content:"";
    position: absolute;
    top:calc(50% - 1px);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transform: rotate(90deg);
  }
  label{
    font-family: "ab-kokikaku", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-right: .5rem;
    margin-left: 1rem;
    color: #fff;
  }
  #generate{
    margin-left: 1rem;
  }
  .flex{

  }
  .right{
    display: flex;
    margin: 0 auto;
    width: 160px;
    text-align: center;
    justify-content: center;
  }
  .saiten{
    position: relative;
    top: -24px;
    right: -105px;
    margin-left: 1rem;
    background-color: #192e40;
  }
  .p{
    position: relative;
    bottom:-1rem;
    padding-top: 2rem;
  }
  main p{
    width: 200px;
    margin: 0 auto;
    font-size: 15pt;
    letter-spacing: .1rem;
  }
  hr{
    color: #fff;
  }
  li{
    list-style: none;
  }
  p,label,h1,h2{
    font-family: "ab-kokikaku", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
  }
  select{
    background-color: #502a59;
  }
  h1{
    color: #192E40;
    margin-top: 10rem;
  }
  h2{
    color: #192E40;
  }
  aside{
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: all 300ms ease 0s;
    transform: translateX(150%);
  }
  aside.on{
    z-index:104;
    height: 100vh;
    transform:translateY(0);
    background-color: #A0DBF2;
  }
  .hantei{
    color: #192E40;
  }
  select,button{
    box-shadow:none;
    color: #fff;
    background-color: #052A59;
  }
  .answer{
    margin-top: 1rem;
    font-size: 20px;
    color: #fff;
  }
  .v-enter-active,
  .v-leave-active{
    transition: all 1s ease 0s;
  }
  .v-enter-from{opacity:0}
  .v-enter-to{opacity:1}
  .v-leave-from{opacity:1}
  .v-leave-to{opacity:0}
</style>

<script>
export default {
  data(){
    return{
      keta:1,
      item:2,
      que:[],// 問題用の配列
      answer:'',
      correct:0,// 配列の合計値
      savelog:[],//採点ログの記録用
    }
  },
  methods:{
    randomGenerate(){
      let total = 0;

      let rmd = [];
      for(let i=1; i<=this.keta; i++){
        if(i == 1){
           // 0を出してはいけない
          total = Math.floor(Math.random()*9)+1;//1-9
        }else{
          total = Math.floor(Math.random()*10);//0-9
        }
        rmd.push(total);
      }
      total = parseInt(rmd.join(''));

      // 配列queにtotalを追加する
      this.que.push(total);
    },
    totalize(){
      let total = 0;
      this.que.forEach(e => total += e);
      this.correct = total;
    },
    onclick(){
      // 生成ボタンを押したときに、項数の分だけ繰り返す
      for(let i=0; i<this.item; i++){
        this.randomGenerate();
      }
      this.totalize();// 合計の答えを出しておく
      this.$refs.input.focus();
      this.flag = !this.flag;
    },
    saiten1(){
      let d = new Date();
      console.log(d.toLocaleString());

      if(this.answer == this.correct){
        // 〇
        console.log('○');
        this.savelog.push({
          "hantei":"○",
          "time":d.toLocaleString()
        });
      }else{
        // ×
        console.log('×');
        this.savelog.push({
          "hantei":"×",
          "time":d.toLocaleString()
        });
        this.saiten = !this.saiten
      }
      this.que = [];//問題配列を空にする
      this.answer = '';//回答も空にする
      this.$refs.gene.focus();//生成ボタンにフォーカス
    },
    saiten2(){
      this.flag = false;
    },
    marucount(){
      let maru = 0;
      this.savelog.forEach(e=>{
        if(e.hantei == '○'){
          maru += 1;//1ずつ加算
        }
      });
      return maru;
    },
    maruper(){
      let per = (this.marucount() / this.savelog.length) * 100;
      console.log(per);
      return (isNaN(per) ? 0:per.toFixed(2) );
    },
    toggle(){
      let aside = document.querySelector('aside');
      aside.classList.toggle('on');
      let button = document.querySelector('button');
      button.classList.toggle('on');
    },
  }
}
</script>
