<template>
  <div class="other">
    <h1>その他</h1>
    <p class="white">
      このページは現在準備中です。
    </p>
    <!-- <p>{{ test }}</p> -->
  </div>
</template>
<style>
.white{
  color: #fff;
}
</style>
<script>
export default{
  data(){
    return{
      test:'テストです',
    }
  }
}
</script>
